import DivixLogo from "../assets/img/divixNegro.png";

export function ConfirmationPage() {
  return (
    <div className="flex flex-col pb-20  min-h-screen">
      <div className="h-56 flex items-center justify-center pt-20 bg-[#e0e0e0]">
        <img className="mx-auto w-24 " src={DivixLogo} alt="Divix Logo" />
      </div>
      <div className="text-center mt-12 mb-20  max-w-md">
        <h1 className="text-[#3a3a3a] text-5xl  mb-4">¡Gracias!</h1>
        <div className="max-w-60 mx-auto pt-2 pb-10">
          <span className="text-[#3a3a3a] text-xl text-center">
            Tu valoración nos sirve para mejorar.
          </span>
        </div>

        <a
          className="text-white bg-primary rounded-full py-1 px-3"
          href="https://www.divix.net"
        >
          www.divix.net
        </a>
      </div>
    </div>
  );
}
