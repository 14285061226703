import { Avatar, Box, CircularProgress, Rating } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import TipService from "../services/TipService";
import DivixLogo from "../assets/img/divixNegro.png";
import { InputComment } from "../components/inputs/InputComment";
import InputAmount from "../components/inputs/InputAmount";
import styled from "@emotion/styled";
import CustomStarIcon from "../components/CustomStarIcon";
import { useLocation, useNavigate } from "react-router-dom";
import {
  AuthorizePaymentRequestDto,
  InitPaymentRequestDto,
} from "../services/dtos/request/tipRequestDto";
import { InitPaymentResponseDto } from "../services/dtos/response/TipResponseDto";
import { Loading } from "../components/Loading";
import UserService from "../services/UserService";

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconFilled": {
    color: "#f3c433",
  },
  "& .MuiRating-iconHover": {
    color: "#f3c433",
  },
}));

const QRLanding = () => {
  const service = useMemo(() => new TipService(), []);
  const userService = useMemo(() => new UserService(), []);
  const [employee, setEmployee] = useState<string>("");

  const [amount, setAmount] = useState<string>("");
  const [rating, setRating] = useState<number | null>(null);
  const [animatedRating, setAnimatedRating] = useState<number>(0);
  const [isAnimating, setIsAnimating] = useState(true);
  const [comment, setComment] = useState<string | null>(null);
  const [error, setError] = useState<{ [key: string]: string }>({});
  const [transientToken, setTransientToken] = useState<string | null>(null);
  const [cybersourceLibraryUrl, setCybersourceLibraryUrl] = useState<
    string | null
  >(null);
  const [cybersourceContextToken, setCybersourceContextToken] = useState<
    string | null
  >(null);

  const [isLoading, setIsLoading] = useState(false);
  const [pendingTip, setPendingTip] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const userId = params.get("userId");
  const branchId = params.get("branchId");

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isAnimating && animatedRating < 5 && employee.length) {
      timer = setTimeout(() => {
        setAnimatedRating((prev) => Math.min(prev + 0.2, 5));
      }, 25);
    }

    if (animatedRating === 5) {
      setTimeout(() => {
        setAnimatedRating(0);
        setIsAnimating(false);
      }, 300);
    }
    return () => clearTimeout(timer);
  }, [animatedRating, isAnimating, employee]);

  var showArgs = {
    containers: {
      paymentSelection: "#buttonPaymentListContainer",
    },
  };

  const authPaymentPostSuccess = (data: any) => {
    console.log("AUTH PAYMENT: " + data?.toString());
  };

  const authPaymentPostError = (error: any) => {
    console.log("AUTH PAYMENT: " + error);
  };

  useEffect(() => {
    if (transientToken) {
      console.log("HAY TRANSIENT TOKEN: " + transientToken);
      var dto = new AuthorizePaymentRequestDto(transientToken);
      dto.Amount = Number(amount);
      dto.RatingMessage = comment;
      dto.RatingValue = Number(rating);
      dto.EmployeeId = Number(userId);
      dto.BranchId = Number(branchId);
      service.pay(dto, authPaymentPostSuccess, authPaymentPostError);
    }
  }, [transientToken]);

  const callCybersourceJsLibrary = () => {
    var authForm: any = document.getElementById("authForm");
    //@ts-ignore
    Accept(cybersourceContextToken)
      .then(function (accept: any) {
        return accept.unifiedPayments();
      })
      .then(function (up: any) {
        return up.show(showArgs);
      })
      .then(function (tt: any) {
        setTransientToken(tt.toString());
        if (authForm) {
          authForm.submit();
        }
      })
      .catch((error: any) => {
        console.log("Error al pagar:" + error.toString());
        alert("Error");
      });
  };

  useEffect(() => {
    if (cybersourceLibraryUrl) {
      if (document.getElementById("cybersourceLibraryScript")) {
        callCybersourceJsLibrary();
      } else {
        let script = document.createElement("script");
        script.id = "cybersourceLibraryScript";
        script.src = cybersourceLibraryUrl;
        let div = document.getElementById("cybersourceLibrary");
        if (div) {
          script.addEventListener("load", () => {
            callCybersourceJsLibrary();
          });
          div.appendChild(script);
        }
      }
    }
  }, [cybersourceLibraryUrl]);

  const handleRatingChange = useCallback(
    (event: React.ChangeEvent<{}>, newRating: number | null) => {
      setRating(newRating);
      setAnimatedRating(newRating || 0);
      setError({
        comment: "",
      });
    },
    []
  );

  // Evitamos el bug cuando seleccionamos el mismo valor no tenia el comportamiento nativo de Rating
  const handleRatingChangeActive = useCallback(
    (event: React.SyntheticEvent<Element, Event>, value: number | null) => {
      if (value === rating) {
        event.preventDefault();
      }
    },
    [rating]
  );

  const postSuccess = () => {};

  const postError = () => {};

  const sendTip = () => {
    const numericAmount = Number(amount);

    console.log("AMOUNT: " + numericAmount);
    console.log("RATING: " + rating);
    console.log("COMMENT: " + comment);

    if (comment && !rating) {
      setError({
        comment: "Por favor, ingrese una valoración",
      });
      return;
    }

    if (!isNaN(numericAmount) && numericAmount > 0 && rating !== null) {
      service.SendTip(
        {
          amount: numericAmount,
          rating,
          comment,
        },
        postSuccess,
        postError
      );
    } else {
      initPayment();
    }
  };

  const postSuccessInitPayment = (data: InitPaymentResponseDto) => {
    console.log(data?.ClientLibraryUrl);
    setCybersourceContextToken(data?.Token);
    setCybersourceLibraryUrl(data?.ClientLibraryUrl);
  };

  const postErrorInitPayment = () => {
    console.log("ERROR initPayment");
  };

  const initPayment = () => {
    const targetOrigins = "https://test.divix-app.pages.dev";
    service.InitPayment(
      new InitPaymentRequestDto(+amount, targetOrigins),
      postSuccessInitPayment,
      postErrorInitPayment
    );
  };

  const handleWithoutTipPayment = async () => {
    setPendingTip(true);

    if (!rating) {
      setPendingTip(false);
      setError({
        comment: "Por favor, ingrese una valoración",
      });
      return;
    }

    await service.withoutTip(
      {
        BranchId: +branchId!,
        EmployeeId: +userId!,
        RatingMessage: comment,
        RatingValue: rating,
      },
      () => {
        navigate("/confirmation");
        setPendingTip(false);
      },
      (errorMessage) => {
        setError({ payment: "Error al procesar la valoración" });
        setPendingTip(false);
      }
    );
  };

  useEffect(() => {
    const controller = new AbortController();
    const { signal } = controller;

    const fetchEmployee = async () => {
      setIsLoading(true);

      try {
        await userService.getEmployee(
          {
            userid: +userId!,
          },
          (data) => {
            setEmployee(data.name);
          },
          (error) => {
            console.log({ error });
          }
        );
      } finally {
        if (!signal.aborted) {
          setIsLoading(false);
        }
      }
    };

    if (userId && branchId) {
      fetchEmployee();
    }
  }, [userService, branchId, userId]);

  return (
    <div className="min-h-screen w-full flex flex-col">
      {isLoading && <Loading />}
      {userId && branchId && (
        <header className="pt-8 bg-[#e0e0e0]">
          <img className="mx-auto w-24 pb-4" src={DivixLogo} alt="Divix Logo" />
          <h3 className="w-[318px] text-nowrap pb-12 mx-auto text-center  text-[#3a3a3a] font-light text-xl sm:text-2xl">
            Califica la atención recibida:
          </h3>
        </header>
      )}
      {userId && branchId && (
        <main className="bg-white flex flex-col justify-center items-center w-full relative">
          <div className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {" "}
            <Avatar
              sx={{
                width: 80,
                height: 80,
                boxShadow: "1px 0px 18px 0 rgba(0,0,0,0.2)",
              }}
              src="https://media.istockphoto.com/id/1327592506/vector/default-avatar-photo-placeholder-icon-grey-profile-picture-business-man.jpg?s=612x612&w=0&k=20&c=BpR0FVaEa5F24GIw7K8nMWiiGmbb8qmhfkpXcp1dhQg="
              alt="Avatar"
            />{" "}
          </div>
          <div className="flex items-end text-black pt-14 mb-4 font-medium shadow-sm max-w-xs text-wrap">
            <div className="py-1 px-3 flex flex-col items-center bg-[#3a3a3a] rounded-full">
              <span className="name text-sm text-center text-[#d6d6d6] md:text-xl leading-5 ">
                {employee}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-center justify-center">
            <StyledRating
              name="simple-controlled"
              value={rating !== null ? rating : animatedRating}
              onChange={handleRatingChange}
              onChangeActive={handleRatingChangeActive}
              size="large"
              icon={<CustomStarIcon isAnimating={isAnimating} />}
              emptyIcon={<CustomStarIcon />}
              sx={{ fontSize: "3rem" }}
            />
            {error.comment && (
              <span className="text-red-500 text-sm">{error.comment}</span>
            )}
          </div>
          <div className="h-fit  w-full max-w-xs lg:max-w-xl pt-4 px-4 text-[#424242] lg:px-16 ">
            <InputComment onChange={(value: string) => setComment(value)} />
          </div>
          <div className="max-w-xs text-[#424242] flex text-5xl pt-2 items-center">
            <InputAmount amount={amount} setAmount={setAmount} />
          </div>
          <div className="h-[1px] max-w-xs w-full shrink-0 bg-[#e0e0e0]" />

          {error.payment && (
            <span className="text-red-500 text-sm">{error.payment}</span>
          )}

          <div className=" pt-8 flex justify-between gap-4 lg:max-w-lg px-4 items-center footer sm:px-10 ">
            <button
              onClick={handleWithoutTipPayment}
              disabled={pendingTip}
              className="rounded-full py-1 w-40 bg-[#3b3b3b] text-white back-button align-middle disabled:opacity-50"
              translate="no"
            >
              {pendingTip ? (
                <div className="flex items-center justify-center">
                  <CircularProgress
                    sx={{
                      color: "#fff",
                    }}
                    size="1.5rem"
                    className="animate-spin "
                  />
                </div>
              ) : (
                <span>SIN PROPINA</span>
              )}
            </button>
            <button
              disabled={amount === null || +amount <= 0 || isLoading}
              className="disabled:opacity-40 rounded-full py-1 w-40 text-white bg-primary"
              onClick={sendTip}
              translate="no"
            >
              DEJAR PROPINA
            </button>
          </div>
          <Box className="py-6">
            <div id="cybersourceLibrary"></div>
            <div id="buttonPaymentListContainer"></div>
          </Box>
        </main>
      )}
    </div>
  );
};

export default QRLanding;
