import { TextField } from "@mui/material";
import { ChangeEvent } from "react";

interface InputCommentProps {
  onChange: (value: string) => void;
}

export function InputComment({ onChange }: InputCommentProps) {
  return (
    <TextField
      name="comment"
      placeholder="Contanos tu experiencia"
      translate="no"
      multiline
      onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
        onChange(e.target.value)
      }
      sx={{
        "& fieldset": {
          paddingLeft: (theme) => theme.spacing(2.5),
          borderRadius: "30px",
        },
        "& .MuiOutlinedInput-root": {
          fontFamily: "Montserrat",
          color: "rgb(66, 66, 66)",
        },
      }}
      rows={3}
      fullWidth
      variant="outlined"
    />
  );
}
